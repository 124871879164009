import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/misc/seo";
import EcoSystemDiagram from "../components/analysis-tools/eco-system-diagram";
import AboutAnalysisToolSection from "../components/analysis-tools/about-analysis-tool-section";
import DestinationsBoxSection from "../components/destinations/destinations-box-section";
import IntegrationFilterSection from "../components/integrations/integrations-filter-section";
import TestimonialSection from "../components/layout/testimonial-section";
import SectionSeparator from "../components/layout/section-separator";
import WhyChooseStitch from "../components/layout/why-choose-stitch";
import AnalysisToolCalloutSection from "../components/analysis-tools/analysis-tool-callout-section";
import { useVideoSelector } from "../hooks/VideoSelector";
import VideoContainer from "../components/misc/video-container";
import CTASection from "../components/misc/cta-section";

const AnalysisTool = ({ data, pageContext }) => {
    const tool = data.contentfulAnalysisTool;
    const menus = pageContext.menus?.items;
    const bodyClasses = `analysis-tools analysis-tools_${tool.slug}`;
    const selectedVideo = useVideoSelector("analysis-tool", [tool.slug]);

    return (
        <Layout
            menus={menus}
            headerType={pageContext.type}
            headerItem={tool}
            pageHeading={`Analyze your data with ${tool.name}`}
            pageSubHeading={`Stitch can replicate data from all your sources to a central warehouse. From there, it's easy to use ${tool.name} to perform the in-depth analysis you need.`}
            dual_cta={true}
        >
            <Seo
                title={`Power ${tool.name} with Stitch: Analyze all your data sources today`}
                description={`Stitch allows you to consolidate all of your data sources into ${tool.name} even more powerful and useful. Click to learn how Stitch can help in just a few clicks.`}
                slug={`/analysis-tools/${tool.slug}`}
                bodyClass={bodyClasses}
            />
            <EcoSystemDiagram logoUrl={tool.logo.file.url} logoAltText={tool.logo.description} />
            <AboutAnalysisToolSection
                name={tool.name}
                logoUrl={tool.logo.file.url}
                logoAltText={tool.logo.description}
                description={tool.description.description}
                url={tool.url}
            />
            <AnalysisToolCalloutSection name={tool.name} tutorial={tool.tutorial} />
            {selectedVideo && (
                <VideoContainer
                    videoEmbedCode={selectedVideo[0].embedCode.embedCode}
                    caption={selectedVideo[0].caption.caption}
                />
            )}
            <DestinationsBoxSection
                heading={`Consolidate all your data in minutes`}
                paragraph={`Stitch delivers your data to the leading data lakes, warehouses, and storage platforms.`}
            />
            <TestimonialSection
                quote={`Stitch gives us more time to focus on the things that matter – instead of building and maintaining custom ETL pipelines we can focus on analysis.`}
                author={`Arvind Ramesh`}
                position={`Data Scientist, Envoy`}
            />
            <SectionSeparator />
            <WhyChooseStitch />
            <CTASection
                heading={`Integrate your data with ${tool.name}`}
                subheading={`Select your integrations, choose your warehouse, and enjoy Stitch free for 14 days.`}
                dualCTA={true}
                containerClasses={`st-section--lowlight`}
            />
            <IntegrationFilterSection
                heading={`Connect to your ecosystem of data sources`}
                paragraph={`Stitch integrates with leading databases and SaaS products. No API maintenance, ever, while you maintain full control over replication behavior.`}
            />
        </Layout>
    );
};

export default AnalysisTool;

export const query = graphql`
    query($slug: String!) {
        contentfulAnalysisTool(slug: { eq: $slug }) {
            contentful_id
            name
            logo {
                file {
                    url
                }
                description
            }
            slug
            url
            description {
                description
            }
            tutorial
        }
    }
`;
